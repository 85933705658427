import api from "./api";

const endPoint = "/trackings";

export const detail = (id) =>
  api
    .get(`${endPoint}/${id}`)
    .then(function(response) {
      return response;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
    });