<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />

    <v-card tile flat>
      <skeleton-page-heading v-if="!datas" />
      <v-card-text v-else-if="datas">
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-card-account-details-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <skeleton-card v-if="!datas" />
            <card-profile-tracking v-else-if="datas" :datas="datas" />
          </v-col>
          <v-col cols="12" md="6" v-if="$vuetify.breakpoint.mdAndUp">
            <v-skeleton-loader
              v-if="!datas"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-else-if="datas"
              contain
              max-height="250"
              src="https://ik.imagekit.io/5zmdzllq3/Detailed_information_h5dUZHWvj.svg"
            ></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import form from "@/mixins/form";
import page from "../mixins/page";
import { detail } from "@/api/tracking";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import CardProfileTracking from "../components/CardProfileTracking.vue";
import SkeletonCard from "../components/SkeletonCard.vue";
export default {
  components: {
    BreadcrumbComponent,
    SkeletonPageHeading,
    LoadingOverlay,
    CardProfileTracking,
    SkeletonCard,
  },
  mixins: [form, page],
  data() {
    return {
      datas: "",
    };
  },
  computed: {
    form: {
      get() {
        return this.datas;
      },
    },
  },
  async created() {
    const {
      data: { data },
    } = await detail(this.$route.params.id);
    this.datas = data;
  },
};
</script>

<style></style>
