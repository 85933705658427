<template>
  <v-card outlined shaped class="tw-shadow-xl" max-width="500">
    <v-card-title>
      <div class="tw-leading-tight">
        <h3 class="tw-text-gray-800">{{ fullname }}</h3>
        <span class="tw-text-sm tw-text-gray-500">
          {{ email }}
          <span class="tw-text-primary" v-if="$vuetify.breakpoint.mdAndUp">
            -
            {{ time }}
            <v-icon color="primary" small class="tw-ml-1"
              >mdi-clock-check-outline</v-icon
            >
          </span>
        </span>
        <br />
        <div
          v-if="$vuetify.breakpoint.smAndDown"
          class="tw-text-sm tw-text-primary tw-mt-2"
        >
          {{ time }}
          <v-icon color="primary" small class="tw-ml-1"
            >mdi-clock-check-outline</v-icon
          >
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-chip small class="tw-tracking-wide tw-font-medium" color="primary">
        {{ role }}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <div class="project">
        <div>
          Project
          <v-divider class="tw-my-2"></v-divider>
        </div>
        <div class="tw-flex">
          <h6 class="tw-text-lg tw-font-medium tw-text-gray-800 tw-capitalize">
            {{ projectName }}
          </h6>

          <v-spacer></v-spacer>

          <v-chip
            dark
            small
            class="tw-tracking-wide tw-capitalize tw-font-medium"
            color="#636e72"
          >
            {{ projectType }}
          </v-chip>
        </div>
        <div class="tw-mb-4 tw-uppercase">
          {{ `${province}, ${city}` }}
        </div>
      </div>
      <div class="property">
        <div>
          Property Detail
          <v-divider class="tw-my-2"></v-divider>
        </div>
        <div class="tw-flex">
          <h6 class="tw-text-lg tw-font-medium tw-text-gray-800 tw-capitalize">
            {{ propertyDetailName }}
          </h6>
          <v-spacer></v-spacer>
          <v-chip
            dark
            small
            class="tw-tracking-wide tw-font-medium"
            color="#2ed573"
          >
            {{ status === false ? "Tidak Aktif" : "Aktif" }}
          </v-chip>
        </div>
        <div class="my-1 tw-font-bold tw-text-gray-800">Rp. {{ price }}</div>
        <div>
          {{ description }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullname() {
      return this.datas.user.name;
    },
    email() {
      return this.datas.user.email;
    },
    time() {
      const time = this.datas.time_counter;
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Math.floor((time % 3600) % 60);

      let hDisplay = hours > 0 ? hours + (hours == 1 ? " jam " : " jam, ") : "";
      let mDisplay =
        minutes > 0 ? minutes + (minutes == 1 ? " menit " : " menit ") : "";
      let sDisplay =
        seconds > 0 ? seconds + (seconds == 1 ? " detik" : " detik") : "";

      let full = hDisplay + mDisplay + sDisplay;
      return full;
    },
    projectName() {
      return this.datas.project.name;
    },
    projectType() {
      return this.datas.project.property_type;
    },
    city() {
      return this.datas.project.district.name;
    },
    province() {
      return this.datas.project.district.province.name;
    },
    propertyDetailName() {
      return this.datas.property_detail.name;
    },
    price() {
      const price = this.datas.property_detail.price;
      var reverse = price
          .toString()
          .split("")
          .reverse()
          .join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan
        .join(".")
        .split("")
        .reverse()
        .join("");
      return ribuan;
    },
    role() {
      return this.datas.user.role.name;
    },
    status() {
      return this.datas.property_detail.active;
    },
    description() {
      return this.datas.property_detail.description;
    },
  },
};
</script>

<style></style>
